@import 'src/design-system/styles/typography.mixins';

.container {
  padding: 0;
  margin: 0;

  & .date {
    margin-bottom: 0;
  }
}

.idContainer {
  display: flex;
  flex-direction: column;
}

.copyToClipboardButton,
.copyToClipboardButton:hover,
.copyToClipboardButton:focus,
.copyToClipboardButton:active {
  @include font-style-small;

  display: flex;
  justify-content: flex-start;

  min-width: 86px;
  padding: 0;
  color: var(--text-link);
  background-color: transparent;

  cursor: pointer;
}

.copyToClipboardButton[disabled],
.copyToClipboardButton[disabled]:hover {
  padding: 0;
  color: var(--text-10);
  background-color: transparent;
  cursor: not-allowed;
}
