@import 'src/design-system/styles/layout-variables.scss';
@import 'src/design-system/styles/typography.mixins';

.container {
  display: grid;
  padding-top: $content-indent-top;
  grid-template-rows: max-content max-content;
  flex-grow: 1;
}

.headerWrapper {
  display: flex;
  align-items: baseline;
  justify-content: left;

  padding: 0 $content-indent-right 0 $content-indent-left;

  & > button > span {
    margin: auto;
  }
}

.title {
  margin: 0 0 16px 0;
  padding: 0;

  @include font-style-h4($font-weight: $font-weight-regular);
  font-family: inherit;
}

.formContainer {
  display: flex;
  justify-content: flex-end;

  margin-bottom: 16px;
  padding: 0 $content-indent-right 0 $content-indent-left;
  z-index: 999;
}
