@import 'src/design-system/styles/typography.mixins';

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;

  & div:first-of-type {
    margin-left: 8px;
  }
}

.smallWrapper {
  @include font-style-small($font-weight-medium);
}

.mediumWrapper {
  @include font-style-default;
}

.largeWrapper {
  @include font-style-subtitle;
}
