@import 'src/design-system/styles/typography.mixins';

.betsListItemStatus {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  border-radius: 50%;

  &.betsListItemStatusWin {
    background-color: var(--system-success);
    color: var(--static-white);
  }

  &.betsListItemStatusWaiting {
    background-color: var(--shape-50);
    color: var(--text-20);
  }

  &.betsListItemStatusLose {
    background-color: var(--system-error);
    color: var(--static-white);
  }

  &.betsListItemStatusReturn {
    background-color: var(--system-warning);
    color: var(--static-black);
  }

  &.betsListItemStatusCancelled {
    background-color: var(--static-gray-10);
    color: var(--static-white);
  }
}

.betsListItemStatus > svg {
  width: 12px;
  height: 12px;
}

.success {
  color: var(--system-success);
}
