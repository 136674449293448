@import 'src/design-system/styles/typography.mixins';

.betsList {
  @include list-styles-reset;
  padding: 8px 0 0 0;
}

.betsListItem {
  display: grid;
  grid-template-columns: 32px 1fr max-content;
  width: 100%;
  padding: 0;

  &:not(:last-of-type) {
    margin: 0 0 14px 0;
  }
}

.itemStatus {
  grid-column: 1/2;
  grid-row: 2/3;
  align-self: center;
  justify-self: center;
}

.betsListItemTitleContainer {
  grid-column: 2/-1;
  grid-row: 1/2;
  display: grid;
  grid-template-columns: minmax(0, max-content) minmax(max-content, 1fr) max-content max-content;
  align-items: center;
  min-height: 22px;
  margin: 0 0 4px 0;
  color: var(--text-20);

  & a {
    margin: 0 10px 0 0;
    @include font-style-small($font-weight-bold);
    @include one-line-ellipsis;
  }

  & h5 {
    margin: 0 10px 0 0;
    @include font-style-small($font-weight-bold);
    @include one-line-ellipsis;
    color: var(--text-20);
  }

  & > time,
  & .betIsInLive {
    grid-column: -1/-2;
    grid-row: 1/2;
    margin: 0 0 0 auto;
    @include font-style-small;
  }

  & .betIsInLive {
    padding: 0;
    color: var(--text-20);
  }

  & > p {
    margin: 0 0 0 4px;
  }
}

.betBroadcast {
  @include button-styles-reset;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 0 0 4px;

  & > span {
    margin: 0 0 0 4px;
  }
}

.betBroadcast:hover,
.betBroadcast:focus {
  opacity: .8;
}

.betBroadcast:focus-visible {
  box-shadow: 0 0 0 1px var(--text-link);
}

.betDateContainer {
  display: flex;
  justify-content: right;
  align-items: center;
}

.sportIcon {
  margin-left: 5px;
}

.expressInsideMatch {
  @include font-style-small;

  padding-left: 5px;
}
