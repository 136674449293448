@import 'src/design-system/styles/typography.mixins';

.button {
  position: relative;
  display: flex;
  align-items: center;
  margin: 0 0 0 auto;

  //этот стиль для браузера Safari, так как там появляется пробел в тексте
  white-space: nowrap;

  & :global(.button-spinner) > svg {
    width: 16px !important;
    height: 16px !important;
  }
}

.disabledButton {
  pointer-events: none;
}

.contentWrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0;
}

.amountChangedIcon {
  margin-left: 4px;
}

.iconLeft {
  position: absolute;
  width: 100%;
  left: 0;
  animation: success-icon .32s cubic-bezier(.42, 0, 1, 1);
  animation-fill-mode: forwards;
}

.cashoutContent {
  padding-left: 16px;
  animation: appear .64s ease-in;
}

$centerPosition: calc(50% - 6px); // 6px => (Icon width / 2)

@keyframes success-icon {
  0% {
    transform: scale(4) translateX($centerPosition);
    opacity: 0;
  }

  40% {
    transform: scale(1) translateX($centerPosition);
    opacity: 1;
  }

  70% {
    transform: scale(1) translateX($centerPosition);
  }

  100% {
    transform: translateX(0%);
  }
}

@keyframes appear {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
