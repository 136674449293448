@import 'src/design-system/styles/typography.mixins';
@import 'src/design-system/styles/typography.variables';

.betStateStatusTitle {
  position: absolute;
  left: 0;
  top: 0;
  width: 5px;
  height: 100%;
  background-color: var(--shape-50);

  & > span {
    @include visuallyHidden;
  }
}
