@import 'src/design-system/styles/typography.mixins';

.liveInfoContainer {
  @include font-style-super-small;

  color: var(--text-20);
  display: inline-flex;
}

.liveIcon {
  color: var(--text-primary);
  margin: 0 3px 1px 8px;
}

.mainScore,
.activePeriod {
  @include font-style-super-small;
}

.scoreDivider {
  position: relative;
  margin-left: 13px;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -7px;
    width: 1px;
    height: 14px;
    background-color: var(--text-40);
  }
}

.activePeriod {
  margin-left: 4px;
}

.pastPeriods,
.overTime {
  @include font-style-super-small;
  color: var(--text-40);
}
