@import 'src/design-system/styles/typography.mixins';

.errorTemplate {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 15px;
  text-align: center;
}

.errorDetails {
  width: 373px;
  text-align: center;
  margin: 0 auto;
}

.header {
  color: var(--text-10);
  margin-top: 16px;
  margin-bottom: 16px;
}

.description {
  @include font-style-default;
  color: var(--text-20);
  margin: 0;
}

.companyLogo {
  width: 215px;
  height: 43px;
  margin: 0 auto 24px;
}

.errorImage {
  width: 200px;
  height: 200px;
  margin: 0 auto;
}
