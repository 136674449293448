@import 'src/design-system/styles/typography.mixins';

.container {
  grid-column: -1/2;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr minmax(0, max-content) auto;
  grid-template-rows: auto auto;
  background-color: var(--shape-30);
  border-radius: 4px;

  & > ul {
    @include list-styles-reset;
    display: grid;

    & > li {
      position: relative;
      display: grid;
      grid-template-rows: auto auto;
      grid-template-columns: 1fr minmax(0, max-content);
    }
  }
}

.betRate {
  @include font-style-small;
  grid-column: -1/-2;
  grid-row: 1/3;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  margin: 10px 0 10px;
  padding: 0;
  box-shadow: -1px 0 0 var(--shape-50);
}

.betRateWithBetIsLive {
  grid-row: 1/4;
}

.shortVersion {
  & .betRate {
    @include font-style-tiny($font-weight-medium);
    width: auto;
    min-width: 32px;
  }
}
