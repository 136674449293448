@import 'src/design-system/styles/typography.mixins';

.stakeTitle {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  padding: 6px 8px;
  min-height: 40px;
  background-color: var(--static-gray-20);
  color: var(--static-white);
  border-radius: 4px 4px 0 0;

  & h4 {
    padding: 0;
    margin: 0 8px;
    color: inherit;
    @include font-style-small($font-weight-bold);
  }

  & h4::first-letter {
    text-transform: capitalize;
  }
}

.stakeTitleWin {
  background-image: linear-gradient(90deg, var(--static-gray-20) 62.32%, var(--system-success) 100%);
}

.stakeTitleLose {
  background-image: linear-gradient(90deg, var(--static-gray-20) 62.32%, var(--system-error) 100%);
}

.stakesInChain {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    right: -10px;
    top: 0;
    width: 3px;
    height: 50%;
  }
}

.stakesInChain:not(.firstItemInChain) {
  &::before {
    background-color: var(--primary-60);
  }
}

.stakeTimeHint {
  &:hover {
    opacity: .7;
    transition: opacity .4s ease-in-out .1s; //TODO: обсудить анимации глобально с Данилой
  }

  & time {
    font-size: $font-size-small;
  }
}

.stakeId {
  @include font-style-small();
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 24px;
  min-height: 24px;
  margin: 0 8px 0 0;
  padding: 0 8px;
  background-color: var(--text-30);
  border-radius: 16px;

  &:hover,
  &:focus {
    opacity: .8;
  }
}

.stakePosition {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 28px;
  min-height: 28px;
  margin: 0 0 0 auto;
  color: var(--text-100);
  background-color: var(--primary-60);
  @include font-style-small($font-weight-bold);
  border-radius: 50%;

  &::before {
    content: '';
    position: absolute;
    left: 100%;
    width: 18px;
    height: 3px;
    background-color: var(--primary-60);
  }
}

.stakeStateTitle {
  margin: 0 0 0 auto;
  padding: 0;
  @include font-style-small($font-weight-bold);
}

.stakeStateTitle + .stakePosition {
  margin: 0 0 0 8px;
}

.tooltipContent {
  min-width: 100px;

  & > div:first-child {
    padding: 18px 30px 14px 16px;
  }
}
