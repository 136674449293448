@import 'src/design-system/styles/typography.mixins';

.container {
  display: flex;
}

.stakeResultsList {
  @include list-styles-reset;
  display: grid;
  grid-template-columns: repeat(3, max-content);
  gap: 6px;
  margin: 0 0 0 15px;

  & > li {
    @include font-style-tiny;
    position: relative;
    display: flex;
    align-items: center;
  }

  & > li::before {
    content: '';
    width: 12px;
    height: 12px;
    margin: 0 2px 0 0;
    border-radius: 50%;
  }
}

.stakeResultsListItemWin::before {
  background-color: var(--system-success);
}

.stakeResultsListItemLoos::before {
  background-color: var(--system-error);
}

.stakeResultsListItemNotCalculated::before {
  background-color: var(--shape-50);
}
