@import 'src/design-system/styles/typography.mixins';
@import 'src/styles/animation-variables';

// --- InformationTile

.expanderContainer {
  position: relative;
  width: 100%;
}

.informationTile {
  @include font-style-small;

  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;
  min-height: 40px;
  padding: 6px 8px;

  background: var(--shape-50);

  & > .closeButton {
    position: absolute;
    top: 10px;
    right: 12px;

    display: flex;
    align-items: center;
    justify-content: center;

    height: 20px;
    width: 20px;
    padding: 0;
    border: none;
    background: transparent;

    & svg path {
      fill: var(--static-white);
      transition: fill $reactiveTransitionDuration $smoothTransitionTiming;
    }

    &:hover,
    &:focus {
      & svg path {
        opacity: .8;
      }
    }
  }

  &.error {
    color: var(--static-white);
    background: var(--system-error);
  }

  &.bettingIsClosed {
    color: var(--text-10);
    background: var(--controls-segmented-bg);

    & > .closeButton {
      & svg path {
        fill: var(--text-10);
      }

      &:hover,
      &:focus {
        & svg path {
          opacity: .8;
        }
      }
    }
  }
}

// ---- MessageText

.confirmCashout {
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  height: 100%;

  z-index: 5;
}

.onlyButtons {
  justify-content: flex-end;
}

.cancelButton {
  margin-left: 8px;
  margin-right: 6px;
}

.cashoutButton,
.cancelButton {
  min-width: 0;
}

.arrow {
  position: absolute;
  top: -4px;
  right: 21px;

  width: 12px;
  height: 12px;

  background: var(--shape-50);

  transform: rotate(45deg);
  transition: opacity .3s ease;
}

.showArrow {
  opacity: 1;
}

.hideArrow {
  opacity: 0;
}

.errorArrow {
  background: var(--system-error);
}

.closedErrorArrow {
  background: var(--controls-segmented-bg);
}
