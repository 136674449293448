
.button {
  background-color: transparent;
  border: none;

  &:disabled > * {
    color: var(--text-40);
    opacity: 1;
  }
}
