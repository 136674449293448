@import 'src/design-system/styles/typography.mixins';

.stakeAmountsList {
  @include list-styles-reset;
  @include font-style-small;
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(3, max-content);
  margin: 0 auto 0 0;
  padding: 8px 0;

  & > li {
    display: flex;
    align-items: center;
  }
}

.stakeAmountsListItemIcon {
  width: 24px;
  height: 24px;
  margin: 0 5px 0 0;
}

.rateAmount {
  font-size: $font-size-small;
  line-height: $line-height-small;
}

.freeBetAmount {
  cursor: pointer;
  padding: 2px 4px;
  background-color: var(--primary-60);
  border-radius: 3px;
  color: var(--text-100);
}
