@import 'src/design-system/styles/typography.mixins';
@import 'src/design-system/styles/typography.variables';

.cancelReason {
  grid-row: 1/-1;
  align-self: center;
  margin-right: 16px;
  padding: 4px 8px;
  color: var(--text-10);
  @include font-style-small;
  border-radius: 4px;
  box-shadow: 0 0 0 1px var(--shape-50);

  & > p {
    margin: 0;
    padding: 0;
  }
}

.cancelReasonWithBetIsLive {
  grid-row: 1/4;
}

.betSubName {
  grid-column: 1/2;
  grid-row: 2/3;
  @include font-style-tiny;
  margin: 0;
  padding: 0 10px 6px 16px;
  color: var(--text-20);
  @include one-line-ellipsis;
}

.betSubNameWithBetIsLive {
  padding: 0 10px 0 16px;
}

.shortBetName {
  @include font-style-tiny($font-weight-medium);
  color: var(--text-30);
  padding: 2px 1px 4px 8px;
}

.stakeName {
  grid-column: 1/2;
  grid-row: 1/2;
  @include font-style-default;
  margin: 0;
  padding: 8px 10px 0 16px;
  color: var(--text-10);
  @include one-line-ellipsis;
}

.shortStakeName {
  @include font-style-tiny($font-weight-medium);
  color: var(--text-20);
  padding: 4px 1px 0 8px;
}

.betLiveBadge {
  grid-row: 3/4;
  margin: 0 0 8px 16px;
  padding: 0 2px;
  width: 25px;
  line-height: 12px;
}

.shortBadge {
  margin-left: 8px;
  margin-bottom: 4px;
}

.popper {
  grid-row: 1/-1;
  align-self: center;
  margin-right: 10px;

  &:hover,
  &:focus {
    color: var(--text-20);
  }
}
