@import 'src/design-system/styles/typography.mixins';

.container {
  display: flex;
  flex-direction: column;

  align-items: center;

  & h3 {
    @include font-style-subtitle($font-weight: $font-weight-bold);
    text-align: center;
    color: var(--text-10);

    margin: 40px 0 4px 0;

    max-width: 261px;
  }

  & p {
    @include font-style-default;
    text-align: center;
    color: var(--text-20);

    margin: 0 0 4px 0;

    max-width: 250px;
  }

  & .redirectBtn {
    margin-top: 22px;
  }
}
