
.stakesListItem {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.stakesListItemChain {
  position: relative;
}

.stakesListItemChain:not(.stakesListLastItemInChain)::before {
  content: '';
  position: absolute;
  right: -10px;
  top: 16px;
  width: 3px;
  height: 100%;
  background-color: var(--static-secondary);
}

.stakesListUnplaced {
  opacity: .5;
}
